<template>
  <div class="component-contact">
    <div class="container">
      <div class="subject">FAQ</div>
      <div class="folders">
        <div class="folder" :class="{ opened: state.openedIdxes[idx] }" v-for="(f, idx) in defines.faqs" :key="idx">
          <div class="head" @click="toggle(idx)">
            <div class="wrapper">
              <div class="inner">
                <div class="title">
                  <span class="text">{{ f.question }}</span>
                </div>
                <div class="desc">{{ f.desc }}</div>
                <i class="fa fa-angle-down"></i>
              </div>
            </div>
          </div>
          <div class="body" v-if="state.openedIdxes[idx]">
            <div>{{ f.answer }}</div>
          </div>
        </div>
      </div>
      <div class="subject">문의하기</div>
      <div class="row">
        <div class="col-lg-4" v-for="(c, idx) in defines.contacts" :key="idx">
          <div class="rect">
            <div class="top">
              <a :href="c.href" :target="c.target">
                <img :src="`/assets/img/${c.ico}`" />
              </a>
            </div>
            <div class="middle">
              <b>{{ c.title }}</b>
            </div>
            <div class="value">
              <a :href="c.href" :target="c.target">
                <span>{{ c.label }}</span>
              </a>
            </div>
            <div class="bottom">
              <a class="btn btn-outline-point" :target="c.target" @click="copy(c.target ? c.href : c.label)">
                <span class="ico">
                  <span class="img active"></span>
                  <span class="img normal"></span>
                </span>
                <span class="txt">주소 복사</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import defines from "../defines.json";
import commonLib from "../libs/commonLib";

export default {
  setup() {
    const state = reactive({
      openedIdxes: [],
    });

    const toggle = (idx) => {
      state.openedIdxes[idx] = !state.openedIdxes[idx];
    };

    const copy = (val) => {
      commonLib.copy(val);
    };

    return { state, defines, toggle, copy };
  },
};
</script>

<style lang="scss" scoped>
.component-contact {
  background: #f7f7f7;
  padding: 25px 0;

  .container {
    > .subject {
      font-size: 18px;
      margin-bottom: 17.5px;
      font-weight: bold;
      padding: 0 5px;
    }

    .folders {
      margin-bottom: 35px;

      .folder {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        font-size: 15px;

        .head {
          background: none;
          padding: 0;
          border: 0;

          > .wrapper {
            cursor: pointer;
            padding: 15px 15px;
            color: inherit;
            text-decoration: none;

            > .inner {
              position: relative;
              padding: 5px 35px 5px 10px;

              .title {
                .text {
                  margin-right: 6px;
                  vertical-align: middle;
                }
              }

              > i {
                position: absolute;
                top: 7.5px;
                right: 0;
                padding: 0 10px;
                font-size: 20px;
                transition: transform 0.25s;
              }
            }
          }
        }

        .body > div {
          padding: 5px 25px 22.5px 25px;
        }

        &:not(:last-child) {
          margin-bottom: -1px;
        }

        &.opened {
          .head > .wrapper > .inner {
            .title .text {
              font-weight: bold;
            }

            > i {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }

    .row > div {
      > .rect {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        text-align: center;
        padding: 100px 25px;

        > div {
          a {
            color: inherit;
            display: inline-block;
            text-decoration: none;
            max-width: 100%;
            position: relative;

            .message {
              width: 140px;
              margin-right: -140px;
              font-size: 14px;
            }
          }

          &.top {
            a img {
              height: 120px;
            }
          }

          &.middle {
            padding-top: 20px;
          }

          &.value {
            padding-top: 5px;

            a {
              padding-right: 23px;
              position: relative;

              span {
                vertical-align: middle;
                display: inline-block;
                color: #777;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 100%;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          &.bottom {
            padding-top: 17px;

            > .btn {
              padding: 8px 12px 10px 12px;

              .ico {
                position: relative;
                height: 16px;
                width: 16px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 7px;
                font-size: 0;

                .img {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;

                  &.normal {
                    background-image: url(/assets/img/ico.copy.svg);
                  }

                  &.active {
                    background-image: url(/assets/img/ico.copy.white.svg);
                  }
                }
              }

              .txt {
                font-size: 14px;
                vertical-align: middle;
              }

              &:hover .ico .img.normal {
                z-index: -1;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      .folders .folder .head > .wrapper {
        padding-left: 10px;
      }

      .row > div {
        .rect {
          padding: 50px 25px;
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
</style>
