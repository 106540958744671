const lib = {
  isNumeric: (str) => {
    if (typeof str === "number") {
      str = str.toString();
    } else if (typeof str != "string") {
      return false;
    }

    return !isNaN(str) && !isNaN(parseFloat(str));
  },

  isOnlyAlphabetString: (str) => {
    return /^[a-zA-Z]+$/.test(str);
  },

  isValidEmail: (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  getHashmapFromCookie: () => {
    let hashmap = lib.cookie.get("hashmap");

    if (hashmap) {
      hashmap = JSON.parse(hashmap);
    } else {
      hashmap = {};
    }

    return hashmap;
  },

  getStrToArray: (str, separator1, separator2) => {
    const result = [];
    const arr1 = str.split(separator1);

    for (let x of arr1) {
      const arr2 = x.split(separator2);

      for (let y of arr2) {
        if (y) {
          result.push(y);
        }
      }
    }

    return result;
  },

  getRenewed: (value) => {
    if (!value) {
      return value;
    }

    return JSON.parse(JSON.stringify(value));
  },

  getTextReplacedDoubleSpacesToSingle: (text) => {
    if (text.includes("  ")) {
      text = text.replaceAll("  ", " ");
      return lib.getTextReplacedDoubleSpacesToSingle(text);
    }

    return text;
  },

  getBrowser: () => {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf("OPR")) != -1) {
      return "opera";
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return "chrome";
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return "safari";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return "firefox";
    } else if (navigator.userAgent.indexOf("MSIE") != -1 || !!document.documentMode == true) {
      return "ie";
    } else {
      return "unknown";
    }
  },

  timer: {},

  isTextSelected: (elem) => {
    if (typeof elem.selectionStart == "number") {
      return elem.selectionStart == 0 && elem.selectionEnd == elem.value.length;
    } else if (typeof document.selection != "undefined") {
      return document.selection.createRange().text == elem.value;
    }
  },

  copy: (text) => {
    const $copyText = document.createElement("textarea");
    $copyText.setAttribute("id", "copyText");
    $copyText.value = text;
    document.body.appendChild($copyText);
    $copyText.select();
    document.execCommand("copy");
    $copyText.remove();
    lib.message.show("success", "클립보드에 복사하였습니다.");
  },

  postForm: (url, args) => {
    const $form = document.createElement("form");
    $form.setAttribute("action", url);
    $form.setAttribute("method", "post");
    // $form.setAttribute("target", "_blank");

    if (args && typeof args === "object" && Object.keys(args).length) {
      for (let i in args) {
        const $input = document.createElement("input");
        $input.setAttribute("type", "hidden");
        $input.setAttribute("name", i);
        $input.setAttribute("value", args[i]);
        $form.appendChild($input);
      }
    }

    document.body.appendChild($form);
    $form.submit();
    $form.remove();
  },

  cookie: {
    get: (name) => {
      if (document.cookie.length > 0) {
        let start = document.cookie.indexOf(name + "=");
        let end;

        if (start != -1) {
          start = start + name.length + 1;
          end = document.cookie.indexOf(";", start);

          if (end == -1) {
            end = document.cookie.length;
          }

          return unescape(document.cookie.substring(start, end));
        }
      }

      return "";
    },
    set: (name, value, shareHost, days) => {
      var expires;
      const domain = shareHost ? (shareHost === "localhost" ? "" : ".") + shareHost : "";

      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toGMTString();
      } else {
        expires = "";
      }

      document.cookie = name + "=" + value + expires + ";domain=" + domain + ";path=/;";
    },
    remove: (name, host) => {
      lib.cookie.set(name, "", host, -1);
    },
  },
};

export default lib;
